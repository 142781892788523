import { Group } from '@mantine/core';
import { IconChartBar, IconUsersGroup } from '@tabler/icons-react';
import { useRouter } from 'next/router';
import { FooterMenuItem } from './FooterMenuItem';

export function Footer() {
  const router = useRouter();
  const currentPath = router.pathname;

  return (
    <Group justify="center" w="100%" pt="md" style={{
      boxShadow: 'rgba(0, 0, 0, 0.05) 0px -4px 12px',
    }}>
      <FooterMenuItem
        href="/fellowships"
        icon={IconUsersGroup}
        label="Fellowships"
        isActive={currentPath === '/fellowships'}
      />

      <FooterMenuItem
        href="/"
        icon={IconChartBar}
        label="Dashboard"
        isActive={currentPath === '/'}
      />
    </Group>
  );
}
