import { useGameMasterBackend } from "../../hooks/useGameMasterBackend";
import LoadingOverlay from "../LoadingOverlay";
import { Button, Flex, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import useNotification from "../../hooks/useNotification";
import { useWeb3Context } from "../../providers/Web3Provider/useWeb3Context";
import { GradientTitle } from "../GradientTitle";
import { useMultipass, generateInputProps } from "../../hooks/useMultipass";

export const RequireMultipass = ({ children }: { children: any }) => {
    const web3ProviderContext = useWeb3Context();
    const multipassService = useMultipass(generateInputProps({ web3ProviderContext }));
    const gameMasterService = useGameMasterBackend();
    const notification = useNotification();
    if (!web3ProviderContext.account) throw new Error("No account found");

    const form = useForm({
        mode: "uncontrolled",
        initialValues: {
            username: "",
        },

        validate: {
            username: (value) =>
                value.length > 2 && value.length < 16
                    ? null
                    : "Enter a username between 2 and 16 characters.",
        },
    });

    if (!multipassService.isUsernameFetched) {
        return <LoadingOverlay overlayId={'requireMultipass'} />;
    }

    const handleSignUp = (values: { username: string }) => {
        if (!web3ProviderContext.account) throw new Error("No account selected");
        gameMasterService.signUp.mutate(
            { username: values.username },
            {
                onSuccess: (gmReturnedData) => {
                    if (!web3ProviderContext.account) throw new Error("No account selected");
                    multipassService.signUp({
                        message: {
                            wallet: web3ProviderContext.account,
                            ...gmReturnedData.message,
                        },
                        registrarSignature: gmReturnedData.signature,
                    });
                },
                onError: (error: any) => {
                    notification(error.message ?? error?.response?.data, "error");
                    console.error("Error:", error);
                },
            }
        );
    };

    if (multipassService.isUsernameFetched && multipassService.username === "")
        return (
            <Flex direction="column" justify="center" gap="1rem" maw="450px" mr="auto" ml="auto">
                <GradientTitle text="New in " gradientText="the hood?" />
                <Text>
                    Hey fellow, haven&apos;t seen your account here before.
                </Text>
                <Text>What shall be your name?</Text>
                <form
                    onSubmit={form.onSubmit((values) => handleSignUp(values))}
                >
                    <Flex
                        direction="column"
                        justify="center"
                        w="100%"
                        gap="1rem"
                    >
                        <TextInput
                            withAsterisk
                            label="Username"
                            placeholder="My name is.."
                            description="Enter a username between 2 and 16 characters."
                            key={form.key("username")}
                            size="md"
                            {...form.getInputProps("username")}
                        />
                        <Button
                            mt={4}
                            mx={0}
                            w="100%"
                            type="submit"
                            loading={
                                gameMasterService.signUp.isPending ||
                                multipassService.isSignUpPending
                            }
                        >
                            Submit
                        </Button>
                    </Flex>
                </form>
            </Flex>
        );

    return children;
};

export default RequireMultipass;
