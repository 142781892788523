import { Web3ProviderInterface } from "../../types/web3";
import { useMultipass, generateInputProps } from "../../hooks/useMultipass";
import { formatDisplayAddress } from "../../utils/address";
import { Menu, Image, Group } from "@mantine/core";
import { useTelegram } from '../../providers/TelegramProvider';
import Logout from './Logout';

const UserMenu = ({
    web3ProviderContext,
}: {
    web3ProviderContext: Web3ProviderInterface;
}) => {
    const { username } = useMultipass(generateInputProps({ web3ProviderContext }));
    const { telegramWebApp } = useTelegram();
    const showLogoutButton = telegramWebApp === undefined;

    return (<Menu shadow="md">
        <Menu.Target>
            <Group gap={5} style={{ cursor: 'pointer' }}>
                <Image
                display="inline" h="24px" mr="0.5rem"
                src="/images/eth-diamond-rainbow.png" alt=''/>
                {username || formatDisplayAddress(web3ProviderContext.account)}
            </Group>
        </Menu.Target>
        { showLogoutButton && (<Menu.Dropdown>
            <Menu.Label><Logout web3ProviderContext={web3ProviderContext} /></Menu.Label>
        </Menu.Dropdown>) }
    </Menu>)
};

export default UserMenu;