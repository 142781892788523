import { MAODistributorClient } from "@peeramid-labs/sdk";
import { useQuery } from "@tanstack/react-query";
import { queryCacheProps } from "./hookCommon";
import { Web3ProviderInterface } from "../types";
import useNotification from "./useNotification";
import { processSdkError } from "../utils/processError";
import { PublicClient, WalletClient } from "viem";

export type useMaoProps = {
    chainId: number,
    publicClient: PublicClient,
    walletClient: WalletClient,
}

export const useMaoInstances = (props: useMaoProps) => {
    const notification = useNotification();

    return useQuery({
        queryKey: ["MaoDistributor", "getMAOInstances", props.publicClient.account, props.chainId],
        queryFn: async () => {
            try {
            const maoDistributor = new MAODistributorClient(props.chainId, {
                publicClient: props.publicClient,
                walletClient: props.walletClient,
            });
            return maoDistributor.getMAOInstances();
            }
            catch (error) {
                processSdkError(error, notification);
            }
        },
        ...queryCacheProps,
        refetchInterval: false,
    });
};

export const useMaoInstance = (props: useMaoProps, maoInstanceId: bigint) => {
    const notification = useNotification();

    return useQuery({
        queryKey: ["mao-instance", props.publicClient.account, maoInstanceId.toString()],
        queryFn: async () => {
            try {
            const maoDistributor = new MAODistributorClient(props.chainId, {
                publicClient: props.publicClient,
                walletClient: props.walletClient,
            });
            return maoDistributor.getMAOInstance({ instanceId: maoInstanceId });
            }
            catch (error) {
                processSdkError(error, notification);
            }
        },
        ...queryCacheProps,
        refetchInterval: false,
    });
};

export const generateInputProps = ({
    web3ProviderContext,
  }: {
    web3ProviderContext: Web3ProviderInterface;
  }) => {
    if (!web3ProviderContext.currentChain) {
        throw new Error("Current chain not initialized");
    }

    return {
        chainId: web3ProviderContext.currentChain.id,
        publicClient: web3ProviderContext.publicClient,
        walletClient: web3ProviderContext.walletClient
    } as useMaoProps;
};
