import React, { useRef } from 'react';
import { Card, Text, Stack, Center, Button } from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import '@mantine/carousel/styles.css';
import { GradientTitle } from '../GradientTitle';
import Autoplay from 'embla-carousel-autoplay';
import { IconUsersGroup } from '@tabler/icons-react';
import { glowingEffect } from '../../styles/animations';
import { useAppRouter } from '../../hooks/useRouter';
import { motion } from 'framer-motion';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';

const WhatsNext: React.FC<{ props?: any }> = ({ props }) => {
    const autoplay = useRef(Autoplay({ delay: 5000 }));
    const router = useAppRouter();


    const FellowshipsButton = () => {
        return (
            <motion.span className={glowingEffect}>
                <Button
                    onClick={(e: any) => {
                        e.preventDefault();
                        router.push('/fellowships');
                    }}
                    className={glowingEffect} mt="xs"
                    leftSection={<IconUsersGroup size={20} stroke={1.5} />}
                    style={{ maxWidth: '200px' }}
                >Fellowships</Button>
            </motion.span>
        );
    };

    return (
        <Card withBorder h="100%">
            <Carousel withIndicators height="100%" withControls={false} pb="3rem"
                plugins={[autoplay.current]}
                onMouseEnter={autoplay.current.stop}
                onMouseLeave={autoplay.current.reset}>
                <Carousel.Slide>
                    <Center>
                        <Stack>
                            <Center>
                                <GradientTitle text="Join the" gradientText="Fun" />
                            </Center>



                            <Center>
                                <Text size="md" weight={500}>Find Your Next Game! ✨</Text>
                            </Center>
                            <Center>
                                <DotLottieReact
                                    src="https://lottie.host/66133bdc-ce5a-4d43-b4ab-9a547ace254b/dyEZZGKg3B.lottie"
                                    loop
                                    autoplay
                                    speed={0.5}
                                    style={{ width: '250px' }}
                                />
                            </Center>
                            <Center>
                                <Text size="sm" weight={500}>
                                    Looking for an exciting game to jump into? Explore fellowships and discover the perfect match for your playstyle!
                                </Text>
                            </Center>


                            <Center>
                                <FellowshipsButton />
                            </Center>
                        </Stack>
                    </Center>
                </Carousel.Slide>
                <Carousel.Slide>
                    <Center>
                        <Stack>
                            <Center>
                                <GradientTitle text="Create" gradientText="Game" />
                            </Center>
                            <Center>
                                <Text size="md" weight={500}>Set up your own game inside fellowships! 🏆🔥</Text>
                            </Center>
                            <Center>
                                <DotLottieReact
                                    src="https://lottie.host/fb04780c-a967-449d-9c67-e69904559577/6K78CofShN.lottie"
                                    loop
                                    autoplay
                                    speed={0.5}
                                    style={{ width: '300px' }}
                                />
                            </Center>
                            <Center>
                                <Text size="sm" weight={500}>
                                    Set up your own game inside fellowships! Customize the rules,
                                    define the game settings, and make it truly yours. Once you&apos;re ready, grab the link and share it with your friends for an epic gaming session.
                                </Text>
                            </Center>
                            <Center>
                                <FellowshipsButton />
                            </Center>
                        </Stack>
                    </Center>
                </Carousel.Slide>
                <Carousel.Slide>
                    <Center>
                        <Stack>
                            <Center>
                                <GradientTitle text="Create" gradientText="Community" />
                            </Center>
                            <Center>
                                <Text size="md" weight={500}>Bring People Together! 🌍💬</Text>
                            </Center>

                            <Center>
                                <DotLottieReact
                                    src="https://lottie.host/ad2a84fb-ecfb-4741-a9cf-5c1bb54915ef/qhSNTgDTta.lottie"
                                    loop
                                    autoplay
                                    speed={0.6}
                                    style={{ width: '300px' }}
                                />
                            </Center>

                            <Center>
                                <Text size="sm" weight={500}>
                                    Create a space that reflects your interests and passions!
                                    Start now and connect with like-minded people! 🤝🔥
                                </Text>
                            </Center>
                            <Center>
                                <Text size="md" weight={500}>
                                    Have fun, facilitate consensus, or achieve a refined solution together ✨🤝
                                </Text>
                            </Center>
                            <Center>
                                <FellowshipsButton />
                            </Center>
                        </Stack>
                    </Center>
                </Carousel.Slide>
            </Carousel>
        </Card>

    );
};

WhatsNext.displayName = 'WhatsNext';

export default WhatsNext;