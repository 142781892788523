import { generateInputProps, useRankifyToken } from "../../hooks/useRankifyToken";
import { useWeb3Context } from "../../providers/Web3Provider/useWeb3Context";
import { Text, Group } from "@mantine/core";
import { Loader } from '../Loader';
import { utils } from "ethers";
import { IconCoins } from "@tabler/icons-react";

const RankTokenBalance: React.FC = () => {
    const web3Context = useWeb3Context();
    const { readBalanceOfReadContract } = useRankifyToken(generateInputProps({ web3ProviderContext: web3Context }));

    if (readBalanceOfReadContract.isPending) {
        return <Loader />
    }

    if (readBalanceOfReadContract.isSuccess) {
        return <Group visibleFrom="sm" gap="5" bg="dark.6" p="5px" pr="10px" pl="10px" style={{ borderRadius: '15px' }}>
            <Text c="">
                <IconCoins size={16} /> RFY:
            </Text>
            <Text fw={600}>
                {utils.formatUnits(readBalanceOfReadContract.data?.toString(), 18)}
            </Text>
        </Group>
    }

    return <></>
};

export default RankTokenBalance;