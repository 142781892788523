import { Button } from '@mantine/core';
import { useRouter } from 'next/router';
import { IconArrowLeft } from '@tabler/icons-react';
import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

export const UpButton: React.FC = () => {
  const router = useRouter();
  const currentPath = router.asPath;
  const theme = useMantineTheme();
  const isXsScreen = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  if (typeof window === 'undefined' || currentPath === '/') {
    return <></>;
  }

  const getUpNavigation = (): { text: string; path: string } => {
    const pathParts = currentPath.split('/').filter(Boolean);
    if (currentPath.startsWith('/game/')) {
      if (pathParts.length > 2) {
        return {
          text: 'To Fellowship',
          path: `/fellowship/${pathParts[1]}`
        };
      }
    }

    if (currentPath.startsWith('/fellowship/')) {
        return {
          text: 'To Fellowships',
          path: '/fellowships'
        };
      }

    // Default case
    return {
      text: '',
      path: ''
    };
  };

  const navigation = getUpNavigation();
  if (navigation.text === '') return <></>;

  return (
    <Button
      variant="transparent" pl="0"
      fw="600"
      leftSection={<IconArrowLeft size={16} />}
      onClick={() => router.push(navigation.path)}
    >
      {isXsScreen ? 'Back' : navigation.text}
    </Button>
  );
};
