import { Button, Group, Text } from '@mantine/core';
import { Icon } from '@tabler/icons-react';
import Link from 'next/link';
import useAppRouter from '../../hooks/useRouter';

interface FooterMenuItemProps {
  href: string;
  icon: Icon;
  label: string;
  isActive: boolean;
}

export function FooterMenuItem({ href, icon: Icon, label, isActive }: FooterMenuItemProps) {
  const router = useAppRouter();

  return (
    <Button
      onClick={(e: any) => {
        e.preventDefault();
        router.push(href);
      }}
      component={Link}
      href={href}
      variant="filled"
      fs="16px"
      color={isActive ? '#B7B7B7' : '#202020'}
      p="10px 20px"
      radius="100px"
      leftSection={<Icon size={20} stroke={1.5} color={isActive ? '#202020' : '#B7B7B7'} />}
    >
      <Text size="sm" c={isActive ? '#202020' : '#B7B7B7'}>
        {label}
      </Text>
    </Button>
  );
}
