import React from 'react';
import { Group, Grid, Image, Text, Stack, Badge, Accordion, Button, Progress, SimpleGrid } from '@mantine/core';
import type { GameState } from '../../types/rankifyGame';
import { useWeb3Context } from '../../providers/Web3Provider/useWeb3Context';
import { Loader } from '../Loader';
import { AlertMessage } from '../AlertMessage';
import { PlayersGameInMao } from '../../types/myActiveGames';
import { useRankifyGameState, useRankifyGame, generateInputProps } from '../../hooks/useRankifyGame';
import { useMaoInstance, generateInputProps as generateMaoInstanceInputProps } from '../../hooks/useMao';
import { useRankTokenMetadata, generateRankTokenInputProps } from '../../hooks/useRankToken';
import { useAppRouter } from '../../hooks/useRouter';
import GameCard from '../games/GameCard';
import { GradientTitle } from '../GradientTitle';

const MyActiveGame: React.FC<{ props: PlayersGameInMao }> = ({ props }) => {
    const router = useAppRouter();
    const web3Context = useWeb3Context();
    const { gameStateDetailsQuery } = useRankifyGameState(generateInputProps({ web3ProviderContext: web3Context, instanceAddress: props.instanceAddress, gameId: props.gameId }));
    const maoInstanceQuery = useMaoInstance(generateMaoInstanceInputProps({ web3ProviderContext: web3Context }), props.maoInstanceId);
    const rankTokenMetadataQuery = useRankTokenMetadata(generateRankTokenInputProps({ web3ProviderContext: web3Context, rankTokenAddress: props.rankTokenAddress }));
    const { useVoting } = useRankifyGame(generateInputProps({ web3ProviderContext: web3Context, instanceAddress: props.instanceAddress, gameId: gameStateDetailsQuery.data?.id ?? 0 }));
    const proposalsAndVotingQuery = useVoting(gameStateDetailsQuery.data?.currentTurn ?? 0);
    const proposed = proposalsAndVotingQuery.data?.proposalEvents?.some(event => event.args.proposer === web3Context.account) ?? false;
    const voted = proposalsAndVotingQuery.data?.voteEvents?.some(event => event.args.player === web3Context.account) ?? false;
    const actionNeeded = !proposed || !voted;
    const colSpan = { base: 24, sm: 12, lg: 8 };

    if (gameStateDetailsQuery.data === undefined || maoInstanceQuery.data === undefined || rankTokenMetadataQuery.data === undefined) {
        return null;
    }

    const isLoading = gameStateDetailsQuery.isLoading || maoInstanceQuery.isLoading || proposalsAndVotingQuery.isLoading || rankTokenMetadataQuery.isLoading;
    const isError = gameStateDetailsQuery.isError || maoInstanceQuery.isError || proposalsAndVotingQuery.isError || rankTokenMetadataQuery.isError;
    const isSuccess = gameStateDetailsQuery.isSuccess && maoInstanceQuery.isSuccess && proposalsAndVotingQuery.isSuccess && rankTokenMetadataQuery.isSuccess;

    const gameState: GameState = gameStateDetailsQuery.data;
    const proposals = proposalsAndVotingQuery.data?.proposalEvents;
    const votes = proposalsAndVotingQuery.data?.voteEvents;


    if (isSuccess) {
        return (
            <>
                {isLoading && <Grid.Col span={colSpan}><Loader /></Grid.Col>}
                {isError && <Grid.Col span={colSpan}><AlertMessage message="There was an error fetching active game! Please try again." /></Grid.Col>}

                {isSuccess &&
                    <Grid.Col span={colSpan} order={actionNeeded ? -1 : 0}>
                        <GameCard
                            game={gameState}
                            rankifyInstanceAddress={props.instanceAddress}
                            maoInstanceId={Number(props.maoInstanceId)}
                            mode='dashboard'
                            fellowshipMetadata={rankTokenMetadataQuery.data}
                            proposals={proposals}
                            votes={votes}
                        />
                    </Grid.Col>
                }
            </>
        );
    }

    return <></>;
};

MyActiveGame.displayName = 'MyActiveGame';

export default MyActiveGame;
