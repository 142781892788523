import { Group, Box } from '@mantine/core';
import UserMenu from './UserMenu';
import { useTelegram } from '../../providers/TelegramProvider';
import { useWeb3Context } from '../../providers/Web3Provider/useWeb3Context';
import RankTokenBalance from './RankTokenBalance';
import { UpButton } from './UpButton';

const Navbar = () => {
    const web3ProviderContext = useWeb3Context();

    if (web3ProviderContext?.isConnected) {
        return (
            <Group h="100%" align="center" justify="space-between" w="100%">
                <Box><UpButton /></Box>
                <Group>
                    <RankTokenBalance/>
                    <UserMenu web3ProviderContext={web3ProviderContext} />
                </Group>
            </Group>
        )
    } else {
        return <></>;
    }
};

export default Navbar;