import { useEffect } from "react";
import { useRouter } from "next/router";
import { useLogin, usePrivy } from "@privy-io/react-auth";
import { Web3ProviderLoader } from "../../components/loaders/Web3ProviderLoader";
import React from "react";
const RequirePrivyAuthorization = ({ children }: { children: JSX.Element }) => {
    const { ready, authenticated} = usePrivy();
    const { login } = useLogin();
    const router = useRouter();

    useEffect(() => {
        if (ready && !authenticated && router.pathname !== "/login") {
            router.push("/login");
        }
    }, [ready, authenticated, router, login]);

    if (!ready || !authenticated) {
        return null;
    }

    return (
        <Web3ProviderLoader>
            {children}
        </Web3ProviderLoader>
    );
};

export default RequirePrivyAuthorization;
