import { useQuery } from "@tanstack/react-query";
import { Address, PublicClient, WalletClient } from "viem";
import { InstancePlayer, MAODistributorClient, type MAOInstanceContracts } from "@peeramid-labs/sdk";
import { queryCacheProps } from "./hookCommon";
import useNotification from "./useNotification";
import { processSdkError } from "../utils/processError";
import { Web3ProviderInterface } from "../types/web3";
import { PlayersGameInMao } from "../types/myActiveGames";

type useMyActiveGamesProps = {
    chainId: number,
    publicClient: PublicClient,
    walletClient: WalletClient,
    account: Address
}

export const useMyActiveGames = (props: useMyActiveGamesProps) => {
    const notification = useNotification();

    const myActiveGamesQuery = useQuery({
        queryKey: ["useMyActiveGames", props.publicClient.account, props.chainId],
        queryFn: async () => {
            try {
                const maoDistributor = new MAODistributorClient(props.chainId, {
                    publicClient: props.publicClient,
                    walletClient: props.walletClient,
                });
                const maoInstances: { maoInstanceId: bigint, instances: MAOInstanceContracts }[] = await maoDistributor.getMAOInstances();
                const playersGameNumbers: PlayersGameInMao[] = await playersGamesArray(maoInstances);
                return playersGameNumbers;
            }
            catch (error) {
                processSdkError(error, notification);
            }
        },
        ...queryCacheProps,
        refetchInterval: false,
    });

    const playersGamesArray = async (maoInstances: { maoInstanceId: bigint; instances: MAOInstanceContracts }[])
        : Promise<PlayersGameInMao[]> => {
        if (!maoInstances) return [];
        const maoInstancesIdWithGameNumber = await Promise.all(maoInstances.map(async maoInstance => {
            return {
                maoInstanceId: maoInstance.maoInstanceId,
                instanceAddress: maoInstance.instances.instance.address,
                gameId: await fetchGameNumberFromContract(maoInstance.instances.instance.address),
                rankTokenAddress: maoInstance.instances.rankToken.address
            };
        }));

        return maoInstancesIdWithGameNumber.filter((x) => x.gameId !== undefined && x.gameId > 0);
    };

    const fetchGameNumberFromContract = async (instanceAddress: Address): Promise<number> => {
        const playerInstance = new InstancePlayer({
            chainId: props.chainId,
            publicClient: props.publicClient,
            walletClient: props.walletClient,
            instanceAddress: instanceAddress,
            account: props.account
        });
        const playersGameId = await playerInstance.getPlayersGame(props.account);
        return Number(playersGameId);
    };

    return {
        myActiveGamesQuery
    };
};

export const generateInputProps = ({
    web3ProviderContext,
  }: {
    web3ProviderContext: Web3ProviderInterface;
  }) => {
    if (!web3ProviderContext.currentChain) {
        throw new Error("Current chain not initialized");
    }

    return {
        chainId: web3ProviderContext.currentChain.id,
        publicClient: web3ProviderContext.publicClient,
        walletClient: web3ProviderContext.walletClient,
        account: web3ProviderContext.account
    } as useMyActiveGamesProps;
};
