



import { getArtifact, abis } from "@peeramid-labs/sdk";
import { Web3ProviderInterface } from "../types";
import { Address } from "viem";
import useNotification from "./useNotification";
import { processError } from "../utils/processError";
import { useReadContract } from "wagmi";
import { useEffect } from "react";

type useRankifyToken = {
    account: Address;
    chainId: number
}

export const useRankifyToken = (props: useRankifyToken) => {
    const notification = useNotification();
    const rankifyContractAddress: Address = getArtifact(props.chainId, "Rankify").address;
    const readBalanceOfReadContract = useReadContract({
        abi: abis.RankifyAbi,
        address: rankifyContractAddress,
        functionName: "balanceOf",
        args: [props.account],
    })

    useEffect(() => {
        if (readBalanceOfReadContract.isError) {
            processError(readBalanceOfReadContract.error, abis.RankifyAbi, notification);
        }
    }, [readBalanceOfReadContract.isError]);

    return {
        readBalanceOfReadContract
    }
}

export const generateInputProps = ({
    web3ProviderContext
  }: {
    web3ProviderContext: Web3ProviderInterface;
  }) => {
    if (!web3ProviderContext.currentChain) {
        throw new Error("Current chain not initialized");
    }
    if (!web3ProviderContext.account) {
        throw new Error("Account not initialized");
    }
    return {
        chainId: web3ProviderContext.currentChain.id,
        account: web3ProviderContext.account
    } as useRankifyToken;
};