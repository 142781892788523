import { getDefaultLayout } from "../layouts";
import MyBalances from '../components/dashboard/myBalances/MyBalances';
import WhatsNext from '../components/dashboard/WhatsNext';
import { Loader, Grid } from "@mantine/core";
import { AlertMessage } from "../components/AlertMessage";
import MyActiveGame from "../components/dashboard/MyActiveGame";
import { useMyActiveGames, generateInputProps } from "../hooks/useMyActiveGames";
import { useWeb3Context } from "../providers/Web3Provider/useWeb3Context";
import { PlayersGameInMao } from "../types/myActiveGames";


const Home = () => {
    const web3Context = useWeb3Context();
    const { myActiveGamesQuery } = useMyActiveGames(generateInputProps({ web3ProviderContext: web3Context }));
    if (myActiveGamesQuery.isSuccess) {
        return (
            <>
                <Grid columns={24} gutter="2rem">
                    <Grid.Col span={{ base: 24, md: 12, lg: 8 }} order={-1000}>
                        <MyBalances></MyBalances>
                    </Grid.Col>
                    {myActiveGamesQuery.data?.length === 0 && <Grid.Col span={{ base: 24, md: 12, lg: 16 }} order={2}>
                        <WhatsNext></WhatsNext>
                    </Grid.Col>}
                    {myActiveGamesQuery.isLoading && <Grid.Col span={{ base: 24, sm: 24 }}><Loader /></Grid.Col>}
                    {myActiveGamesQuery.isError && <Grid.Col span={{ base: 24, sm: 24 }}>
                        <AlertMessage message="There was an error fetching your active games! Please try again." /></Grid.Col>}
                    {myActiveGamesQuery.isSuccess && myActiveGamesQuery.data?.map((playersGameInMao: PlayersGameInMao, index) => (
                        <>
                            <MyActiveGame key={playersGameInMao.gameId + playersGameInMao.maoInstanceId.toString()}
                                props={playersGameInMao} />
                        </>
                    ))}
                </Grid>
            </>
        );
    }

    return <></>;
};

Home.getLayout = getDefaultLayout;
export default Home;