import React from 'react';
import { Box, Grid, Text, Card } from '@mantine/core';
import { useWeb3Context } from '../../../providers/Web3Provider/useWeb3Context';
import { Loader } from '../../Loader';
import { IconCoins } from '@tabler/icons-react';
import classes from './MyBalances.module.css';
import blockies from "ethereum-blockies";
import Username from '../../Username';
import { useRankifyToken, generateInputProps } from '../../../hooks/useRankifyToken';
import { utils } from 'ethers';
import { AlertMessage } from '../../AlertMessage';

const MyBalances: React.FC<{ props?: any }> = ({ props }) => {
    const web3Context = useWeb3Context();
    const { readBalanceOfReadContract } = useRankifyToken(generateInputProps({ web3ProviderContext: web3Context }));

    const colSpan = { base: 24, sm: 12, lg: 8 };

    const CryptoIdenticon = () => {
        const canvasRef = React.useRef<HTMLDivElement>(null);

        React.useEffect(() => {
            if (canvasRef.current && web3Context.account) {
                const canvas = blockies.create({
                    seed: web3Context.account.toLowerCase(),
                    color: '#008080',
                    bgcolor: '#F5F5F5',
                    size: 23,
                    scale: 4,
                    spotcolor: '#5f3dc4'
                });
                canvasRef.current.innerHTML = '';
                canvasRef.current.appendChild(canvas);
            }
        }, []);

        return <Box ref={canvasRef} />;
    };

    return (
        <>
            <Card withBorder h="100%" style={{ alignItems: 'center' }}>
                <Box className={classes['first-box'] + ' ' + classes['user-box']} display="flex" style={{ height: '100%', alignItems: 'center' }}>
                    <Box className={classes['account-wrapper']} style={{ animationDelay: '0.8s' }}>
                        <Box className={classes['account-profile']}>
                            <CryptoIdenticon />
                            <Box className={classes['blob-wrap']}>
                                <div className={classes['blob']}></div>
                                <div className={classes['blob']}></div>
                                <div className={classes['blob']}></div>
                            </Box>
                            <Box className={classes['account-title']}>
                                <Username address={web3Context.account} noTooltip />
                            </Box>
                        </Box>
                        <Box mt="xs">
                            <Text fz="12px">{web3Context.account}</Text>
                        </Box>
                        <Box mt="md" ml="-xl" mr="-xl" className={classes.account + ' ' + classes['card']}>
                            <IconCoins />
                            <Box className={classes['account-cash']}>
                                {readBalanceOfReadContract.isPending && <Loader />}
                                {readBalanceOfReadContract.isError && <Grid.Col span={colSpan}><AlertMessage message="There was an error fetching active game! Please try again." /></Grid.Col>}
                                {readBalanceOfReadContract.isSuccess && <>{utils.formatUnits(readBalanceOfReadContract.data?.toString(), 18)} RFY</>}
                            </Box>
                            <Box className={classes['account-income']}>LVL1 - Music challenge</Box>
                            <Box className={classes['account-income']}>LVL2 - EIP Fun discussion club</Box>
                        </Box>
                    </Box>
                </Box>
            </Card>
        </>
    );

    return <></>;
};

MyBalances.displayName = 'MyActiveGame';

export default MyBalances;
